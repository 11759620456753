import { render, staticRenderFns } from "./VisitorFeedbackDetail.vue?vue&type=template&id=1b334355&scoped=true"
import script from "./VisitorFeedbackDetail.vue?vue&type=script&lang=ts"
export * from "./VisitorFeedbackDetail.vue?vue&type=script&lang=ts"
import style0 from "./VisitorFeedbackDetail.vue?vue&type=style&index=0&id=1b334355&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b334355",
  null
  
)

export default component.exports