
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'GuideCommentDetail'
})
export default class extends Vue {
  private detailInfo = null

  created () {
    const id = this.$route.params.id as string
    this.getDetail(id)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.visitor.selectAppUserFeedbackByFeedbackId, {
        feedbackId: id
      })
      .then((res) => {
        this.detailInfo = res
      })
  }
}
